import React, { useEffect, useState } from 'react'
import './App.css'

const SCENE_ASPECT_RATIO = 4.0 / 3.0

function App() {
  const [[sceneWidth, sceneHeight], setSceneSize] = useState([0, 0])

  // For testing, you can set any of the widget params in the URL bar and they will be
  // passed along to the iframe
  const searchParams = new URLSearchParams(window.location.search)
  const type = searchParams.get('type')
  const url = searchParams.get('url')
  const collection = searchParams.get('collection')

  // Build widget iframe URL
  const params = new URLSearchParams({
    apiToken: process.env.REACT_APP_AVATAR_WEBKIT_AUTH_TOKEN!
  })

  if (type) params.set('type', type)
  if (url) params.set('url', url)
  if (collection) params.set('collection', collection)

  const widgetUrl = `${process.env.REACT_APP_WIDGET_URL}?${params.toString()}`

  // iframe resizing
  useEffect(() => {
    const windowDidResize = () => {
      // Calculate potential width/height for scene based on both screen width & height
      // At least 10% padding on every side
      const widthBasedW = window.innerWidth * 0.8
      const widthBasedH = widthBasedW / SCENE_ASPECT_RATIO

      const heightBasedH = window.innerHeight * 0.8
      const heightBasedW = heightBasedH * SCENE_ASPECT_RATIO

      // Treating widthBasedW & heightBasedH as max width/height,
      // choose one or the other
      if (widthBasedH > heightBasedH) {
        // Base on max height
        setSceneSize([heightBasedW, heightBasedH])
      } else {
        // Base on max width
        setSceneSize([widthBasedW, widthBasedH])
      }
    }

    windowDidResize()
    window.addEventListener('resize', windowDidResize)

    return () => {
      window.removeEventListener('resize', windowDidResize)
    }
  }, [sceneWidth, sceneHeight])

  return (
    <div className="App">
      <header className="App-header">
        <iframe
          src={widgetUrl}
          allow="microphone; camera; clipboard-write;"
          title="Avatar Widget"
          width={sceneWidth}
          height={sceneHeight}
          style={{ border: 'none' }}
        />
      </header>
    </div>
  )
}

export default App
